import React from 'react'

export default function PuriInfo() {
  const year = new Date().getFullYear()
  return (
    <div style={{ color: 'rgb(220,220,220)' }} className="padding-all-10">
      <div className="bold-text font-18 marginBottom-8">Puritan Reformed</div>
      <div className="font-14">
        <div>Visit us at: <a style={{ color: `rgba(150,150,150,0.8)` }} target='blank' href="https://prts.edu">prts.edu</a></div>
        <div>2965 Leonard St NE,</div>
        <div>Grand Rapids, MI 49525</div>
        <div className="marginTop-8" style={{ color: `rgba(150,150,150,0.8)` }}>
          <div>Puritan Reformed <span>&copy;</span> {year}</div>
        </div>
      </div>
    </div>
  )
}
