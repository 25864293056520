import React from 'react'
import {ThreeDots} from 'react-loader-spinner'

export default function Button({name, className, loading, onClick, bgClassName, disabled}) {
    if(loading){
        return (
            <div 
            className={`flex-column justify-content-center align-items-center round cursor-progress ${bgClassName ?? 'red-bg'}`}>
                <ThreeDots 
                    height="40" 
                    width="40" 
                    radius="9"
                    color="#fff" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>
        )
    }
  return (
    <button 
        disabled={disabled ?? false}
        onClick={onClick}
        className={`white-text login ${className ?? 'red-bg'}`}
    >
        {name ?? 'Button'}
    </button>
  )
}
