import React from 'react'
import { Oval } from 'react-loader-spinner'

export default function Loader({color, heightClass, height, width}) {
  return (
    <div className={`width-100 flex-column justify-content-center align-items-center ${heightClass ?? 'height-100'}`}>
      <div>
      <Oval
        height={height ?? 80}
        width={width ?? 80}
        color={color ?? "#4fa94d"}
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor={color ?? "#4fa94d"}
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
      </div>
    </div>
  )
}
