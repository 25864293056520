import { createContext, useEffect } from 'react'
import { MsalProvider, useIsAuthenticated, useMsal, AuthenticatedTemplate } from '@azure/msal-react'
import { PublicClientApplication, InteractionStatus } from '@azure/msal-browser'
import { msalConfig, loginRequest } from '../config/authConfig'

const msalInstance = new PublicClientApplication(msalConfig)

export { AuthenticatedTemplate }

/* TODO:
  The current implementation uses localStorage to preserve some elements
  This breaks the ability to use multiple AuthContexts simultaneously; some thought
  should be given to fixing this behavior
*/
const setToken = (account, token) => {
  localStorage.setItem('token', token)
}

export const getToken = () => localStorage.getItem('token') // TODO: move to context

const removeToken = () => localStorage.removeItem('token')

export const useAuthenticatedUser = () => {
  const auth = useAuth()
  useEffect(() => {
    auth.login()
  }, [auth.isAuthenticated, auth.inProgress])
  return auth.account
}

export const useAuth = () => {
  const { instance, accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const account = accounts[0]

  const login = () => {
    if(!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch(err => {
        console.log('Login error', err)
      })
    }
  }

  const logout = () => {
    removeToken()
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    })
  }

  const refresh = async() => {
    const response = await instance.acquireTokenSilent({...loginRequest, account: account})
    setToken(account, response.accessToken)
  }

  return {
    // Status 
    account,
    isAuthenticated,
    inProgress,
    // Functionality
    login,
    logout,
    refresh,
  }
}

export const AuthProvider = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      {children}
    </MsalProvider>
  )
}

