import React, {useContext} from 'react'
import { Context } from '../config/Provider'
import { FcMenu } from "react-icons/fc";


export default function TopNav() {
  const state = useContext(Context)
  return (
    <div id="topnavicon" className="fixed top-0 width-100 padding-all-10 white-bg" style={{ zIndex: 1000}}>
      <div className="font-20 cursor-pointer">
        <FcMenu onClick={state.toggleMenu} />
      </div>
    </div>
  )
}
