import { 
    FcIdea, FcReading, FcPlanner, FcDocument, FcAlarmClock, FcClock, FcGraduationCap 
} from "react-icons/fc";

const sideContent = [
    {name: 'CEMEM', link: '/', icon: <FcGraduationCap />},
    {name: 'Competencies', link: '/competencies', icon: <FcIdea />},
    {name: 'Assignments', link: '/assignments', icon: <FcPlanner/>},
    {name: 'Assessments', link: '/assessments', icon: <FcAlarmClock/>},
    {name: 'Calendar', link: '/calendar', icon: <FcClock />},
    {name: 'Members', link: '/members', icon: <FcReading />},
    {name: 'Reports', link: '/reports', icon: <FcDocument />}
]

export default sideContent