import React, {useContext} from 'react'
import { Context } from '../config/Provider'
import { useAuth } from './Auth'
import {NavLink, useNavigate} from 'react-router-dom'

import sideContent from '../config/sidecontent'
import Logo from './Logo'
import Button from './Button'
import PuriInfo from './PuriInfo'
import Fullname from './Fullname'

export default function SideBar() {
  const auth = useAuth()
  const state = useContext(Context)
  const navigation = useNavigate()

  const goToLink = (e, link) => {
    e.preventDefault()
    navigation(link)
    state.falseMenu()
  }

  const sideItems = sideContent.map((item, index)=>(
    <NavLink to={item.link} key={index} onClick={(e)=>goToLink(e, item.link)}>
      <div className="nav-menu flex-row align-items-center">
        <div className="font-20 marginRight-5">{item.icon}</div>
        <div className='truncate'>{item.name}</div>
      </div>
    </NavLink>
  ))

  return (
    <div 
      style={{zIndex: 2000}}
      className={`fixed width-l-40 width-m-50 width-s-70 height-100 sidebar overflow-y-auto ${state.menu ? 'active' : ''}`}
    >
      <div className='white-bg'>
        <Logo src="../../images/logo.svg" className={'width-80 padding-all-10'}/>
      </div>

      <div>
        <Fullname />
        {sideItems}
        <div className="padding-all-20" />
        <div className="width-80 margin-auto">
        <Button name="Logout" className="blue-bg" onClick={auth.logout} />
        </div>
        <div className="padding-all-10" />
        <PuriInfo />
      </div>
    </div>
  )
}
