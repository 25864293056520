import {createContext, useState, useEffect} from 'react'

export const Context = createContext({
    menu: false,
    toggleMenu: ()=>{},
    falseMenu: () =>{},
})


export default function Provider({children}){
    const [menu, setMenu] = useState(false)
    const toggleMenu = () => setMenu(!menu)
    const falseMenu = () => setMenu(false)

    return (
        <Context.Provider
        value={{
            menu,
            toggleMenu,
            falseMenu,
        }}
        >
            {children}
        </Context.Provider>
    )
}
