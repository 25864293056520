import {lazy, Suspense} from 'react'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import ErrorPage from './pages/ErrorPage'

import Loader from './components/Loader'

const lazyRetry = (action) => {
  return lazy(async () => action().then(
    (result) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false') // success so reset the refresh
      return result
    },
    (error) => {
      // check if the window has already been refreshed
      const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') ?? 'false')
      if (!hasRefreshed) {
        // Attempt retry by refreshing page
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true')
        return window.location.reload()
      }
      throw error // Reraise error if retry over
    }
  ))
}

const lazyPageImport = (pagePath) => lazyRetry(() => import(`./pages/${pagePath}`))

const Dash = lazyRetry(() => import('./layout/Dash'))
const Members = lazyPageImport('members/Members')
const Competencies = lazyPageImport('competencies/Competencies')
const ViewCompetencies = lazyPageImport('competencies/ViewCompetencies')
const Assignments = lazyPageImport('assignments/Assignments')
const AddAssignment = lazyPageImport('assignments/AddAssignment')
const EditAssignment = lazyPageImport('assignments/EditAssignment')
const ViewAssignment = lazyPageImport('assignments/ViewAssignment')
const Assessments = lazyPageImport('assessments/Assessments')
const AddAssessment = lazyPageImport('assessments/AddAssessment')
const AddAssessee = lazyPageImport('assessments/AddAssessee')
const ViewAssessments = lazyPageImport('assessments/ViewAssessments')
const EditAssessment = lazyPageImport('assessments/EditAssessment')
const Reports = lazyPageImport('reports/Reports')
const AssessmentReport = lazyPageImport('reports/AssessmentReport')
const TimeTracking = lazyPageImport('reports/TimeTracking')
const Welcome = lazyPageImport('welcome/Welcome')
const Timelogs = lazyPageImport('timelogs/Timelogs')
const AddTimelog = lazyPageImport('timelogs/AddTimelog')
const ViewTimelog = lazyPageImport('timelogs/ViewTimelog')
const IntakeSurvey = lazyPageImport('submissions/IntakeSurvey')
const IntakeSurveyView = lazyPageImport('submissions/IntakeSurveyView')
const Mentor = lazyPageImport('submissions/Mentor')
const MentorView = lazyPageImport('submissions/MentorView')
const Student = lazyPageImport('submissions/Student')
const StudentView = lazyPageImport('submissions/StudentView')


export default function AppRoute() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Suspense fallback={<Loader />}>
          <Dash />
        </Suspense>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/',
          element: <Welcome />,
        },
        {
          path: '/members',
          element: <Members />,
        },
        {
          path: '/competencies',
          element: <Competencies />,
        },
        {
          path: '/competencies/:id',
          element: <ViewCompetencies />,
        },
        {
          path: '/assignments',
          element: <Assignments />,
        },
        {
          path: '/assignments/add',
          element: <AddAssignment />,
        },
        {
          path: '/assignments/:id',
          element: <ViewAssignment />,
        },
        {
          path: '/assignments/edit/:id',
          element: <EditAssignment />,
        },
        {
          path: '/assessments',
          element: <Assessments />,
        },
        {
          path: '/assessments/add/new',
          element: <AddAssessment />,
        },
        {
          path: '/assessments/add/new/:id/:type/:assessee',
          element: <AddAssessee />,
        },
        {
          path: '/assessments/:id',
          element: <ViewAssessments />,
        },
        {
          path: '/assessments/edit/:id',
          element: <EditAssessment />,
        },
        {
          path: '/reports',
          element: <Reports />,
        },
        {
          path: '/reports/assessment/:reportee_id/',
          element: <AssessmentReport />,
        },
        {
          path: '/reports/assessment/:reportee_id/:competency_id',
          element: <AssessmentReport />,
        },
        {
          path: '/reports/timetracking/:reportee_id/:start_date/:end_date',
          element: <TimeTracking />,
        },
        {
          path: '/calendar',
          element: <Timelogs />,
        },
        {
          path: '/calendar/add',
          element: <AddTimelog />,
        },
        {
          path: '/calendar/view/:id',
          element: <ViewTimelog />,
        },
        {
          path: '/submissions/intake/:id',
          element: <IntakeSurvey />,
        },
        {
          path: '/submissions/intake/view/:id',
          element: <IntakeSurveyView />,
        },
        {
          path: '/submissions/mentor/:id',
          element: <Mentor />,
        },
        {
          path: '/submissions/mentor/view/:id',
          element: <MentorView />,
        },
        {
          path: '/submissions/student/:id',
          element: <Student />,
        },
        {
          path: '/submissions/student/view/:id',
          element: <StudentView />,
        },
      ],
    },
  ])
  return (
    <RouterProvider router={router} />
  )
}
