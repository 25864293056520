import React from 'react'
import AppRoute from './AppRoute'
import Provider from './config/Provider';

function App() {

  return (
    <Provider>
      <AppRoute />
    </Provider>
  );
}

export default App;
