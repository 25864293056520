import React from 'react'
import { useAuth } from './Auth'
import { FcApprove } from 'react-icons/fc'

export default function Fullname() {
  const auth = useAuth()
  const fullname = auth.account?.name

  return !fullname ? null : (
    <div title={fullname} className="nav-name flex-row align-items-center">
      <div className="font-20" style={{ marginRight: 5 }}><FcApprove /></div>
      <div className='truncate uppercase'>{fullname}</div>
    </div>
  )
}
