import React, { useEffect } from 'react'
import { NavLink, useNavigate, useRouteError } from 'react-router-dom'
import { useAuth } from '../components/Auth'

import Logo from '../components/Logo'
import Button from '../components/Button';
import logoImage from "../images/logo.svg"

import sideContent from '../config/sidecontent'
import SideBar from '../components/SideBar';
import TopNav from '../components/TopNav';
import PuriInfo from '../components/PuriInfo';
import Fullname from '../components/Fullname'

export default function Dash() {
  const auth = useAuth()
  const navigation = useNavigate()
  const goHome = () => navigation('/')
  const error = useRouteError()

  const sideItems = sideContent.map((item, index)=>(
    <NavLink to={item.link} key={index}>
      <div className="nav-menu flex-row align-items-center">
        <div className="font-20" style={{ marginRight: 5 }}>{item.icon}</div>
        <div className='truncate'>{item.name}</div>
      </div>
    </NavLink>
  ))

  return (
    <>
      <TopNav />
      <div className="row">
        <div id="sidemenubar" className="col-2 col-lx-3">
          <div className="height-100 overflow-y-auto scrollbar-width-thin">
            <div className='white-bg'>
              <Logo 
                src={logoImage} 
                className={'width-80 padding-all-10'}
              />
            </div>

            <div>
              <Fullname />
              {sideItems}
              <div className="padding-all-10" />
              <div className="width-80 margin-auto">
                <Button name="Logout" className="blue-bg" onClick={auth.logout} />
              </div>
              <div className="padding-all-10" />

              <PuriInfo />
            </div>
          </div>
        </div>

        <div id="main" className="col-10 col-lx-9 col-l-12 col-m-12 col-s-12">
          <div className="height-100 off-white-bg font-avenir-regular overflow-y-auto scrollbar-width-thin1">
            <div className="width-100 height-100 flex-column justify-content-center align-items-center off-white-bg">
              <div className="center-text font-helvetica">
                <h1 className="bold-text font-25">Oops!</h1>
                <br />
                <p>Sorry, an unexpected error has occurred.</p>
                <p><i>{error.statusText || error.message}</i></p>
                <br />
                <div>
                  <button className='blue-bg white-text' onClick={goHome}>Go Back to HomePage</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SideBar />
    </>
  )
}
